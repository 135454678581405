import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SchematicsComponent } from '../lib/modules/schematics/schematics.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  fileContent;
  growOptions = [
    'GROW_BOTTOM',
    'GROW_TOP',
    'GROW_LEFT',
    'GROW_RIGHT',
    'GROW_MIDDLE',
  ];

  // workingFormGroup = new FormGroup({
  //   value: new FormControl(false),
  //   name: new FormControl(null),
  //   type: new FormControl('WORKING'),
  // });

  // growFormGroup = new FormGroup({
  //   value: new FormControl(null),
  //   name: new FormControl(null),
  //   type: new FormControl(this.growOptions[0]),
  // });

  // displayFormGroup = new FormGroup({
  //   value: new FormControl(false),
  //   name: new FormControl(null),
  //   type: new FormControl('DISPLAY'),
  // });

  // textFormGroup = new FormGroup({
  //   value: new FormControl(null),
  //   name: new FormControl(null),
  //   type: new FormControl('TEXT'),
  // });

  boolFormGroup = new FormGroup({
    value: new FormControl(null),
    name: new FormControl(null),
  });

  textFormGroup = new FormGroup({
    value: new FormControl(null),
    name: new FormControl(null),
  });

  form = new FormArray([
    this.boolFormGroup,
    this.textFormGroup,
    // this.workingFormGroup,
    // this.growFormGroup,
    // this.displayFormGroup,
    // this.textFormGroup,
  ]);

  @ViewChild('ref', { read: ViewContainerRef })
  overlayRef: ViewContainerRef;
  componentRef: ComponentRef<SchematicsComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  dropHandler(ev) {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          this.onUpload(file);
        }
      }
    } else {
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log(
          '... file[' + i + '].name = ' + ev.dataTransfer.files[i].name
        );
      }
    }
  }

  onUpload(file: File) {
    const reader = new FileReader();
    reader.onload = (rdr) => {
      this.loadChild(reader.result);
    };
    reader.readAsDataURL(file);
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  loadChild(src) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SchematicsComponent
    );
    const viewContainerRef = this.overlayRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    // this.changeDetectorRef.detectChanges();
    this.componentRef.instance.src = src;
    // this.changeDetectorRef.detectChanges();
    // this.componentRef.instance.initialValues = this.form.value.filter(
    // (v) => !!v.name
    // );
    this.componentRef.instance.initialValues = [
      {
        value: 12,
        name: 'POMPOWNIA-GROWBOTTOM',
      },
      {
        value: 26,
        name: 'POMPOWNIA-TEXT',
      },
      {
        value: 0,
        name: 'OTWARCIE-WLAZU-DISPLAY',
      },
      {
        value: 1,
        name: 'POMPA-1-WORKING',
      },
      {
        value: 1,
        name: 'POMPA-1-WATER',
      },
      {
        value: 0,
        name: 'POMPA-1-ALERT',
      },
      {
        value: 1,
        name: 'POMPA-2-WORKING',
      },
      {
        value: 1,
        name: 'POMPA-2-WATER',
      },
      {
        value: 0,
        name: 'POMPA-2-ALERT',
      },
    ];
    this.changeDetectorRef.detectChanges();
  }

  send() {
    this.form.value
      .filter((v) => !!v.name)
      .forEach((v) => {
        this.componentRef.instance.action$.next(v);
      });
  }

  ngOnInit() {}
}
