import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Action } from '../../models/action.model';

@Component({
  selector: 'lib-schematics',
  templateUrl: './schematics.component.html',
  styleUrls: ['./schematics.component.css'],
})
export class SchematicsComponent implements AfterViewInit {
  @Input() src;
  @Input() initialValues: Action[];
  public action$: Subject<Action> = new Subject<Action>();
  public loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(
    private iconReg: SvgIconRegistryService,
    private elementRef: ElementRef
  ) {}

  private setGrowStyle(scale, origin, element, action) {
    element.style.display = 'block';
    element.style.transition = 'transform ease .3s';
    element.style.transformBox = 'fill-box';
    element.style.transformOrigin = origin;
    element.style.transform = `${scale}(${action.value / 100})`;
  }

  private getAnimation(element, parameters) {
    element.style.transition = null;
    element.style.transform = `translate(0%, 0%)`;
    setTimeout(() => {
      element.style.transition = `transform ${parameters[0]}s linear 0s`;
      element.style.transform = `translate(${parameters[1]}%, ${parameters[2]}%)`;
    }, 20);
  }

  private run(action: Action, element: HTMLElement) {
    const split = action.name.split('-');
    let type = split[split.length - 1];
    const typeSplit = type.split(':');
    type = typeSplit.length > 1 ? typeSplit[0] : type;
    const parameters = typeSplit as any[];
    parameters.splice(0, 1);

    switch (type) {
      case 'WATER': {
        const value = Number(action.value);
        if (value) {
          element.style.display = 'block';
          element.style.strokeDasharray = '10';
          element.style.animation = `dash 180s linear infinite`;
        } else {
          element.style.display = 'none';
        }
        return;
      }
      case 'WORKING': {
        element.style.display = 'block';
        const value = Number(action.value);
        (element.style as any).transformBox = 'fill-box';
        element.style.transformOrigin = 'center';
        element.style.opacity = value ? '1' : '0.4';
        element.style.transform = `rotate(0deg)`;
        if (value) {
          element.style.animation = `rotating 2s linear infinite`;
        } else {
          element.style.animation = null;
        }
        return;
      }
      case 'ANIMATE': {
        const value = Number(action.value);
        parameters[1] = parameters[1]
          ? Number(parameters[1].replace('n', '-'))
          : 0;
        parameters[2] = parameters[2]
          ? Number(parameters[2].replace('n', '-'))
          : 0;
        this.getAnimation(element, parameters);
        if (value) {
          (element.style as any).transformBox = 'fill-box';
          element.style.transformOrigin = 'center';
          element.style.display = 'block';
          if ((element as any).flatSchematicAnimation) {
            clearInterval((element as any).flatSchematicAnimation);
            (element as any).flatSchematicAnimation = null;
          }
          Object.assign(element, {
            flatSchematicAnimation: setInterval(
              this.getAnimation.bind(null, element, parameters),
              parameters[0] * 1000
            ),
          });
        } else {
          element.style.display = 'none';
          if ((element as any).flatSchematicAnimation) {
            clearInterval((element as any).flatSchematicAnimation);
            (element as any).flatSchematicAnimation = null;
          }
          setTimeout(() => {
            element.style.transition = null;
            element.style.transform = `translate(0%, 0%)`;
          }, 20);
        }
        return;
      }
      case 'ALERT': {
        element.style.display = 'block';
        const value = Number(action.value);
        if (value) {
          element.style.visibility = 'visible';
          element.style.animation = `blinking 1s steps(5, start) infinite`;
        } else {
          element.style.visibility = 'hidden';
          element.style.animation = null;
        }
        return;
      }
      case 'GROWLEFT': {
        this.setGrowStyle('scaleX', 'center left', element, action);
        return;
      }
      case 'GROWRIGHT': {
        this.setGrowStyle('scaleX', 'center right', element, action);
        return;
      }
      case 'GROWBOTTOM': {
        this.setGrowStyle('scaleY', 'bottom center', element, action);
        return;
      }
      case 'GROWTOP': {
        this.setGrowStyle('scaleY', 'top center', element, action);
        return;
      }
      case 'GROWMIDDLE': {
        this.setGrowStyle('scale', 'center', element, action);
        return;
      }
      case 'DISPLAY': {
        const value = Number(action.value);
        element.style.display = value ? 'block' : 'none';
        return;
      }
      case 'TEXT': {
        element.style.display = 'block';
        const subEl = element.querySelector('text');
        if (subEl) {
          subEl.innerHTML = `${action.value}`;
        } else {
          element.innerHTML = `${action.value}`;
        }
        return;
      }
      default:
        break;
    }
  }

  private getEelement(id) {
    try {
      const el = document.getElementById(id);
      return el;
    } catch (er) {
      console.error(`Nie znaleziono na schemacie akcji o nazwie ${id}.\n`, er);
      return null;
    }
  }

  ngAfterViewInit() {
    this.iconReg
      .loadSvg(this.src)
      .pipe(
        tap(() => {
          this.loaded$.next(true);
          this.initialValues.forEach((initialValue) => {
            const elem = this.getEelement(initialValue.name);
            if (elem) {
              this.run(initialValue, elem);
            }
          });
        }),
        switchMap(() => this.action$),
        tap((action) => {
          const elem = this.getEelement(action.name);
          if (elem) {
            this.run(action, elem);
          }
        })
      )
      .subscribe();
  }
}
