import { SchematicsModule } from './../lib/modules/schematics/schematics.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SchematicsComponent } from '../lib/modules/schematics/schematics.component';
import { SafePipe } from './pipes/safe.pipe';
import { AppComponent } from './app.component';
@NgModule({
  declarations: [AppComponent, SafePipe],
  imports: [
    SchematicsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [SchematicsComponent],
})
export class AppModule {}
