import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchematicsComponent } from '../schematics/schematics.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [SchematicsComponent],
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [SchematicsComponent],
})
export class SchematicsModule {}

// 90 - 90% wypełnienia

// 90 - tekst zamieni się na "90"

// 1 | true - zębatka zacznie się obracać<br>
// 0 | false - zębatka się zatrzymuje

// 1 | true - animacja wody uruchamia się<br>
// 0 | false - woda znika

// 1 | true - alert na osadzie miga<br>
// 0 | false - alert na osadzie znika

// 1 | true - alert miga<br>
// 0 | false - alert znika
